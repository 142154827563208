import React from "react"
import tw, { styled } from "twin.macro"
import _ from "lodash"
import moment from "moment-timezone"
import { getContrastText } from "../utils"
import { TZ } from "../constants"

const ListItemWrapper = styled.div`
  ${tw`w-full text-sm sm:text-lg py-3 px-2 min-h-[140px] flex flex-col justify-center items-center`}
  background-color: ${props => (props.bgColor ? props.bgColor : "#ffffff")};
  color: ${props => (props.color ? props.color : "#000000")};
  box-shadow: 0 -1px 0 0 #999999, 0 1px 0 0 #999999;
`
const DateContainer = styled.div`
  ${tw`text-center`}
`
const Date = styled.div`
  ${tw`text-xs`}
`

const NameContainer = styled.div`
  ${tw`flex justify-center whitespace-nowrap text-lg`}
`

const ScheduleListEventItem = ({ event }) => {
  const { startDate, endDate, highlightColor, name } = event.node.frontmatter

  const bgColor = highlightColor
  const color = getContrastText(bgColor)

  return (
    <ListItemWrapper color={color} bgColor={bgColor}>
      <DateContainer>
        <Date>
          {moment.tz(startDate, TZ).format("MMM D")} -{" "}
          {moment.tz(endDate, TZ).format("MMM D, YYYY")}
        </Date>
      </DateContainer>
      <NameContainer>{name}</NameContainer>
    </ListItemWrapper>
  )
}

export default ScheduleListEventItem
