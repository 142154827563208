import React from "react";
import tw, { styled } from "twin.macro";
import Select from "react-select";

const FilterContainer = styled.div`
  ${tw`flex flex-col items-center justify-center flex-wrap sm:items-start sm:flex-row xl:items-center mb-2 bg-white p-2 rounded border border-gray-200 sm:p-0 sm:border-0`}
`;

const InputContainer = styled.div`
  ${tw`w-[180px] mr-0 sm:mr-4 my-1 text-sm`}
`;
const Button = styled.button`
  ${tw`border border-gray-300 text-xs px-4 py-2.5 font-bold rounded my-1 disabled:text-gray-200`}
  color: ${(props) => props.color};
  &:not(:disabled) {
    border-color: ${(props) => props.color};
    border-opacity: 0.5;
  }
`;

const selectBorderStyle = "2px solid #9e9e9e";

const WeekDayOptions = [
  {
    value: 1,
    label: "Monday",
  },
  {
    value: 2,
    label: "Tuesday",
  },
  {
    value: 3,
    label: "Wednesday",
  },
  {
    value: 4,
    label: "Thursday",
  },
  {
    value: 5,
    label: "Friday",
  },
  {
    value: 6,
    label: "Saturday",
  },
  {
    value: 0,
    label: "Sunday",
  },
];

const HomeAwayOptions = [
  {
    value: true,
    label: "Home",
  },
  {
    value: false,
    label: "Away",
  },
];

// const PromoOptions = [
//   {
//     value: true,
//     label: "Promo Games",
//   },
//   {
//     value: false,
//     label: "Non Promo Games",
//   },
// ];

const ScheduleFilters = ({
  opponentOptions = [],
  locationOptions = [],
  leagueOptions = [],
  filters = {},
  setFilters = () => {},
  resetFilters = () => {},
  hasFilter,
  color,
  site,
}) => (
  <FilterContainer>
    <InputContainer>
      <Select
        options={WeekDayOptions}
        value={filters.weekday}
        onChange={(option) => setFilters({ ...filters, weekday: option })}
        placeholder="Day of the Week"
        styles={{
          control: (baseStyle, state) => ({
            ...baseStyle,
            border: selectBorderStyle,
          }),
        }}
      />
    </InputContainer>
    <InputContainer>
      <Select
        options={HomeAwayOptions}
        value={filters.homeAway}
        onChange={(option) => setFilters({ ...filters, homeAway: option })}
        placeholder="Home/Away"
        styles={{
          control: (baseStyle) => ({
            ...baseStyle,
            border: selectBorderStyle,
          }),
        }}
      />
    </InputContainer>
    {!_.isEmpty(opponentOptions) && (
      <InputContainer>
        <Select
          options={opponentOptions}
          value={filters.opponent}
          onChange={(option) => setFilters({ ...filters, opponent: option })}
          placeholder="Opponent"
          styles={{
            control: (baseStyle) => ({
              ...baseStyle,
              border: selectBorderStyle,
            }),
          }}
        />
      </InputContainer>
    )}
    <InputContainer>
      <Select
        options={locationOptions}
        value={filters.location}
        onChange={(option) => setFilters({ ...filters, location: option })}
        placeholder="Location"
        styles={{
          control: (baseStyle) => ({
            ...baseStyle,
            border: selectBorderStyle,
          }),
        }}
      />
    </InputContainer>
    {["diamondbacks"].includes(site) && (
      <InputContainer>
        <Select
          options={leagueOptions}
          value={filters.league}
          onChange={(option) => setFilters({ ...filters, league: option })}
          placeholder="League"
          styles={{
            control: (baseStyle) => ({
              ...baseStyle,
              border: selectBorderStyle,
            }),
          }}
        />
      </InputContainer>
    )}
    {/* <InputContainer>
        <Select
          options={PromoOptions}
          value={filters.promo}
          onChange={option => setFilters({ ...filters, promo: option })}
          placeholder="Promo"
          styles={{
            control: baseStyle => ({
              ...baseStyle,
              border: selectBorderStyle,
            }),
          }}
        />
      </InputContainer> */}
    <Button onClick={() => resetFilters()} disabled={!hasFilter} color={color}>
      Clear Filters
    </Button>
  </FilterContainer>
);

export default ScheduleFilters;
